import styled, { css } from 'styled-components'

const style = css`
  position: relative; // nested page 내부에 사용될 경우 기준점
  min-height: inherit;
  height: 100%;

  > .body {
    height: 100%;
    overflow-y: auto;
  }
`

export const StyledModalContent = styled.div`
  ${style}
`

export const StyledModalContentForm = styled.form`
  ${style}
`
