import styled from 'styled-components'

import { commonCss } from './modal-container.style'

const StyledHeader = styled.div`
  ${commonCss}
  padding: var(--spacing-legacy-15);
  margin-bottom: var(--spacing-legacy-10);
  padding-bottom: var(--spacing-legacy-10);
  box-shadow: var(--shadow-bottom);

  .title {
    font-size: var(--font-size-16);
  }
`

export default StyledHeader
