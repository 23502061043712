import styled from 'styled-components'

const StyledNestedPage = styled.div`
  /* 모달 overay 컴포넌트의 relative가 기준 */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  will-change: opacity, transform;
  transition: opacity 0.3s, transform 0.3s;
  transition-timing-function: ease-in-out;
  transform-origin: top middle;
  opacity: 0;
  transform: translateX(100%) scaleY(0.8);
  z-index: var(--z-1);

  &.active {
    opacity: 1;
    transform: translateX(0) scaleY(1);
  }

  > .header {
    background-color: var(--color-gray-19);
    padding: var(--spacing-legacy-15);
  }
`

export default StyledNestedPage
