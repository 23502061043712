import * as React from 'react'
import { createPortal } from 'react-dom'

import useClassName from '@/utils/array/class-name-hook'

import StyledNestedPage from './modal-nested-page.style'

type Props = {
  isActive: boolean
  children: React.ReactNode
  headerLeft?: React.ReactNode | false
  title?: React.ReactNode
  headerRight?: React.ReactNode | false
  targetElRef?: React.RefObject<HTMLElement> // ModalContainer 이나 ModalContent ref 에 장착
}

// 모달에서 쇼 하이드 기능이 있는 중첩된 페이지
// 오른쪽에서 왼쪽 방향으로 애니메이션 적용
// glass-mophism 디자인 내장
const ModalNestedPage = ({
  targetElRef,
  isActive,
  children,
  headerLeft,
  headerRight,
  title,
}: Props) => {
  const [render, setRender] = React.useState(false)
  const className = useClassName(['--glass-mophism', isActive && 'active'])
  const component = React.useMemo(() => {
    return (
      <StyledNestedPage className={className}>
        {/* isActive 로 조건부 렌더 해주면 children 에 form elemtent 로 핸들링하는 커뮤니티 포트폴리오 토픽 선택같은 UI 핸들링할 수 없음 */}
        <div className="--flex-x-y-between --sticky header">
          {headerLeft === false ? null : headerLeft === undefined ? <div /> : headerLeft}
          {title}
          {headerRight === false ? null : headerRight === undefined ? <div /> : headerRight}
        </div>
        <div className="contents">{children}</div>
      </StyledNestedPage>
    )
  }, [children, className, headerLeft, headerRight, title])

  React.useEffect(() => {
    // 최초 렌더에 targetElRef?.current 없기 때문 대응
    setRender(true)
  }, [])

  if (!render) {
    return null
  }

  return targetElRef?.current ? createPortal(component, targetElRef.current) : component
}

export default ModalNestedPage
