import React from 'react'

type Props = {
  separator?: string
}

export default function useClassName(
  classNames: (string | boolean | undefined | null)[],
  { separator = ' ' }: Props = {},
) {
  return React.useMemo(
    () => classNames.filter((className) => !!className).join(separator) || undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...classNames, separator],
  )
}
