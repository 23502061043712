import styled, { css } from 'styled-components'
import ReactModal from 'react-modal'

export const style: typeof ReactModal.defaultStyles = {
  overlay: {
    ...ReactModal.defaultStyles.overlay,
    background: 'var(--color-black-a75)',
    display: 'flex', // center 역할
  },
}

export const commonCss = css`
  background-color: var(--color-gray-19);
`

const contentMobileFullLayoutCSS = css`
  @media (max-width: 767px) {
    position: absolute;
    inset: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // 아래 스타일들은 인라인 값들을 덮어씀
    width: 100% !important;
    height: auto !important;
    min-height: inherit !important;
  }
`

export const StyledContent = styled.div<{ mobileFullLayout: boolean }>`
  ${commonCss}
  margin: auto;
  border: none;
  border-radius: var(--border-radius-12);
  overflow-x: hidden;

  @media (min-width: 768px) {
    max-height: calc(100vh - (var(--spacing-legacy-32) * 2));
  }

  ${(props) => props.mobileFullLayout && contentMobileFullLayoutCSS}
`
