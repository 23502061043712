import * as React from 'react'

import CloseButton from '@/components/buttons/close-button'

import StyledHeader from './modal-header.style'

export type ModalHeaderProps = {
  title?: string
  onClose?: () => void
}

const ModalHeader = ({ title, onClose }: ModalHeaderProps) => {
  return (
    <StyledHeader className="--sticky  --flex-x-y-between">
      <div className="title">{title}</div>
      {onClose && <CloseButton size={25} data-test-id="close-modal" onClick={onClose} />}
    </StyledHeader>
  )
}

export default ModalHeader
