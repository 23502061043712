import * as React from 'react'

import StyledFooter from './modal-footer.style'

type Props = {
  children: React.ReactNode
}

const ModalFooter = ({ children }: Props) => {
  return <StyledFooter>{children}</StyledFooter>
}

export default ModalFooter
