// http://reactcommunity.org/react-modal/
import type { Props } from './modal-container'

export { default as ModalContainer } from './modal-container'
export { default as ModalContent } from './modal-content'
export { default as ModalHeader } from './modal-header'
export { default as ModalFooter } from './modal-footer'
export { default as ModalNestedPage } from './modal-nested-page'
export { default as Modal } from './modal'

export { default as BottomModal } from './bottom-modal'
export { default as TransparentModal } from './transparent-modal'

export type ModalBaseProps = Required<Pick<Props, 'isOpen' | 'onClose'>>
