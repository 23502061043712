import * as React from 'react'
import type { Props as ReactModalProps, Styles } from 'react-modal'

import ModalContainer from './modal-container'

type Props = ReactModalProps & {
  onClose: () => void
}

const style: Styles = {
  content: {
    backgroundColor: 'transparent',
  },
  overlay: {},
}

const TransparentModal = ({ children, ...props }: Props) => {
  return (
    <ModalContainer {...props} mergeStyle={style} mobileFullLayout={false}>
      {children}
    </ModalContainer>
  )
}

export default TransparentModal
