import * as React from 'react'

import { getSizeBy375 } from '@/utils/style/size'
import { useMobileSize } from '@/utils/browser/resize-hook'

import StyledCloseButton from './styled'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: number
  className?: string
  onClick: () => void
}

const CloseButton = ({ className, size, onClick, ...buttonProps }: Props) => {
  const { isMobile } = useMobileSize()
  const _size = React.useMemo(() => {
    if (typeof size === 'number') {
      return isMobile ? getSizeBy375(size) : `${size}px`
    }

    return size
  }, [size, isMobile])

  return (
    <StyledCloseButton
      {...buttonProps}
      type="button"
      className={className}
      style={{ width: _size, height: _size }}
      onClick={onClick}
    />
  )
}

export default CloseButton
