import * as React from 'react'
import ReactModal from 'react-modal'

import ModalContainer, { animationMS } from './modal-container'
import ModalContent from './modal-content'
import ModalHeader from './modal-header'

import { ModalBaseProps } from '.'

type Props = ReactModal.Props &
  ModalBaseProps &
  Parameters<typeof ModalContainer>[0] &
  Parameters<typeof ModalHeader>[0] &
  Parameters<typeof ModalContent>[0] & {
    withoutHeader?: boolean
    modalContentRef?: React.MutableRefObject<HTMLDivElement | HTMLFormElement>
  }

/**
 * isOpen prop으로 모달을 오픈하는 방식이 아닌
 * 조건부로 렌더해서 사용할 수 있도록 고안된 모달
 */
const Modal = ({
  modalContentRef,
  pcWidth,
  pcHeight,
  pcMinHeight,
  mobileFullLayout,
  mergeStyle,
  children,
  isOpen,
  title,
  onClose,
  as,
  footer,
  header,
  withoutHeader,
}: Props) => {
  const [isRender, setIsRender] = React.useState(false)
  const timeoutIdRef = React.useRef<NodeJS.Timeout | null>(null)
  const close = React.useCallback(() => {
    setIsRender(false)
    timeoutIdRef.current = setTimeout(onClose, animationMS)
  }, [onClose])
  const _header = React.useMemo(() => {
    if (header) return header
    if (!withoutHeader && (title || onClose)) {
      return <ModalHeader title={title} onClose={close} />
    }
  }, [close, header, onClose, title, withoutHeader])

  React.useEffect(() => {
    setIsRender(true)
    return () => {
      timeoutIdRef.current && clearTimeout(timeoutIdRef.current)
    }
  }, [])

  return (
    <ModalContainer
      isOpen={isRender && isOpen}
      pcWidth={pcWidth}
      pcHeight={pcHeight}
      pcMinHeight={pcMinHeight}
      mobileFullLayout={mobileFullLayout}
      mergeStyle={mergeStyle}
      onClose={close}>
      <ModalContent as={as} ref={modalContentRef} header={_header} footer={footer}>
        {children}
      </ModalContent>
    </ModalContainer>
  )
}

export default Modal
