import styled from 'styled-components'

import { commonCss } from './modal-container.style'

const StyledFooter = styled.div`
  ${commonCss}
  position: sticky;
  bottom: 0;
  margin-top: auto;
  padding: var(--spacing-legacy-15);
`

export default StyledFooter
