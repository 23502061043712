import styled from 'styled-components'

const StyledCloseButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  background: none;
  border: 0;

  @media (min-width: 768px) {
    opacity: 0.5;
  }

  :hover {
    opacity: 1;
  }
  :before,
  :after {
    position: absolute;
    top: 0;
    content: ' ';
    height: 100%;
    width: 1px;
    background-color: rgb(166 179 189);
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`

export default StyledCloseButton
