import * as React from 'react'
import type { Props as ReactModalProps, Styles } from 'react-modal'

import ModalContainer from './modal-container'

type Props = ReactModalProps & {
  onClose: () => void
}

const modalStyle: Styles = {
  overlay: {
    backgroundColor: 'var(--color-black-a75)',
  },
  content: {
    position: 'absolute',
    top: 'initial',
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    border: 0,
    borderRadius: 'var(--border-radius-20) var(--border-radius-20) 0 0',
    background: 'none',
  },
}

const BottomModal = ({ children, ...props }: Props) => {
  return (
    <ModalContainer style={modalStyle} contentElement={undefined} {...props}>
      {children}
    </ModalContainer>
  )
}

export default BottomModal
